import React from 'react';
import logo from './logo.svg';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "./static/lumen-bootstrap.min.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import DApp from './DApp';


function App() {
  return (
    <div className="App">
      <DApp/>
    </div>
  );
}

export default App;
