import React, { useCallback, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { makeStyles } from "@material-ui/styles";
import { shadows } from "@mui/system";
import Box from "@mui/material/Box";

import IArcadeTheme from "./static/art/themes/arcade.png";
import ICottonCandyTheme from "./static/art/themes/cotton_candy.png";
import IDarkTheme from "./static/art/themes/dark.png";
import ILightTheme from "./static/art/themes/light.png";
import IRecordTheme from "./static/art/themes/record.png";
import ISolanaTheme from "./static/art/themes/solana.png";
import ISpaceTheme from "./static/art/themes/space.png";
import ISpectrumTheme from "./static/art/themes/spectrum.png";
import ITurtleTheme from "./static/art/themes/turtle.png";
import IZenTheme from "./static/art/themes/zen.png";

import IPolSpectrumTheme from "./static/art/themes/polaroid-spectrum.png";
import IPolDarkTheme from "./static/art/themes/polaroid-dark.png";
import IPolIceTheme from "./static/art/themes/polaroid-ice.png";

import DiskStyle from "./static/art/styles/disk.gif";
import RipplesStyle from "./static/art/styles/ripples.gif";
import PolaroidStyle from "./static/art/styles/polaroid.gif";
import { SoundprintTheme } from "./soundprint/State";
import {
  ArcadeTheme,
  CottonCandy,
  DarkTheme,
  LightTheme,
  RecordTheme,
  SolanaTheme,
  SpaceTheme,
  SpectrumTheme,
  ZenTheme,
} from "./soundprint/DiskTheme";
import {
  PolaroidDarkTheme,
  PolaroidIceTheme,
  PolaroidSpectrumTheme,
} from "./soundprint/PolaroidTheme";

class Theme {
  name: string;
  url: string;
  cls: SoundprintTheme;

  constructor(name: string, url: string, cls: SoundprintTheme) {
    this.name = name;
    this.url = url;
    this.cls = cls;
  }
}

class Style {
  name: string;
  url: string;
  themes: Theme[];

  constructor(name: string, url: string, themes: Theme[]) {
    this.name = name;
    this.url = url;
    this.themes = themes;
  }
}

const DISK_THEMES = [
  new Theme("Arcade", IArcadeTheme, new ArcadeTheme()),
  new Theme("Cotton Candy", ICottonCandyTheme, new CottonCandy()),
  new Theme("Dark", IDarkTheme, new DarkTheme()),
  new Theme("Light", ILightTheme, new LightTheme()),
  new Theme("Record", IRecordTheme, new RecordTheme()),
  new Theme("Solana", ISolanaTheme, new SolanaTheme()),
  new Theme("Space", ISpaceTheme, new SpaceTheme()),
  new Theme("Spectrum", ISpectrumTheme, new SpectrumTheme()),
  // new Theme("Turtle", ITurtleTheme, new DarkTheme()),
  new Theme("Zen", IZenTheme, new ZenTheme()),
];

const POLAROID_THEMES: Theme[] = [
  new Theme("Spectrum", IPolSpectrumTheme, new PolaroidSpectrumTheme()),
  new Theme("Dark", IPolDarkTheme, new PolaroidDarkTheme()),
  new Theme("Ice", IPolIceTheme, new PolaroidIceTheme()),
];

const RIPPLES_THEMES: Theme[] = [];

const STYLES = [
  new Style("Disk", DiskStyle, DISK_THEMES),
  new Style("Polaroid", PolaroidStyle, POLAROID_THEMES),
  new Style("Ripples", RipplesStyle, RIPPLES_THEMES),
];

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
}));

function srcset(
  image: string,
  width: number,
  height: number,
  rows = 1,
  cols = 1
) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ThemePicker({
  onThemePicked,
}: {
  onThemePicked: (theme: SoundprintTheme) => void;
}) {
  const [currStyle, setCurrStyle] = React.useState<Style>(STYLES[0]);
  const [currTheme, setCurrTheme] = React.useState<Theme>(currStyle.themes[0]);

  useEffect(() => {
    onThemePicked(new ArcadeTheme());
  }, [onThemePicked]);

  const onStylePicked = useCallback(
    (style: Style) => {
      setCurrStyle(style);
      setCurrTheme(style.themes[0]);
      onThemePicked(style.themes[0].cls);
    },
    [setCurrStyle, onThemePicked]
  );

  const onThemeClicked = useCallback(
    (theme: Theme) => {
      setCurrTheme(theme);
      onThemePicked(theme.cls);
    },
    [setCurrTheme, onThemePicked]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          fontFamily: "Roboto",
          fontSize: 18,
          marginBottom: "0.25em",
          fontWeight: 300,
        }}
      >
        Styles
      </Box>
      <StyleCards onStylePicked={onStylePicked} />
      <Box
        sx={{
          fontFamily: "Roboto",
          fontSize: 18,
          marginBottom: "0.25em",
          marginTop: "2em",
          fontWeight: 300,
        }}
      >
        Themes
      </Box>
      <ThemeCards
        themes={currStyle.themes}
        currTheme={currTheme}
        onThemePicked={onThemeClicked}
      />
    </Box>
  );
}

const ThemeCards = ({
  themes,
  currTheme,
  onThemePicked,
}: {
  themes: Theme[];
  currTheme: Theme;
  onThemePicked: (theme: Theme) => void;
}) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        borderRadius: 9,
        overflowY: "hidden",
        margin: 0,
        padding: 0,
        boxShadow: 3,
      }}
    >
      <ImageList
        sx={{
          width: 250,
          height: 500,
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: "translateZ(0)",
          backgroundColor: "#78909c",
          margin: 0,
          padding: "1em",
        }}
        rowHeight={156}
        gap={15}
        className={classes.root}
      >
        {themes.map((theme: Theme, idx) => {
          const cols = 1;
          const rows = 1;

          return (
            <ImageListItem
              key={idx}
              cols={cols}
              rows={rows}
              sx={{
                boxShadow:
                  theme.name == currTheme.name
                    ? "0 0 10px 15px DeepSkyBlue"
                    : 5,
                borderRadius: 10,
              }}
              onClick={() => {
                onThemePicked(theme);
              }}
            >
              <img
                {...srcset(theme.url, 60, 60, rows, cols)}
                alt={theme.name}
                loading="lazy"
                style={{
                  borderRadius: 30,
                  // border: "2px solid grey",
                }}
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.8) 0%, " +
                    "rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)",
                  borderBottomRightRadius: 30,
                  borderBottomLeftRadius: 30,
                  fontWeight: 500,
                }}
                title={theme.name}
                position="bottom"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
};

const StyleCards = ({
  onStylePicked,
}: {
  onStylePicked: (style: Style) => void;
}) => {
  const [currStyle, setCurrStyle] = React.useState<Style>(STYLES[0]);
  const classes = useStyles();

  return (
    <Box
      sx={{
        borderRadius: 9,
        overflowY: "hidden",
        margin: 0,
        padding: 0,
        boxShadow: 3,
      }}
    >
      <ImageList
        sx={{
          width: 250,
          height: 156 * 1.5,
          transform: "translateZ(0)",
          backgroundColor: "#78909c",
          padding: "1em",
          // borderRadius: 10,
          margin: 0,
          // boxShadow: 3,
        }}
        rowHeight={156}
        gap={15}
        className={classes.root}
      >
        {STYLES.map((style: Style, idx) => {
          const cols = 1;
          const rows = 1;

          return (
            <ImageListItem
              key={idx}
              cols={cols}
              rows={rows}
              sx={{
                borderRadius: 20,
                boxShadow:
                  style.name == currStyle.name
                    ? "0 0 10px 15px DeepSkyBlue"
                    : 5,
              }}
              onClick={() => {
                if (style.themes.length > 0) {
                  onStylePicked(style);
                  setCurrStyle(style);
                } else {
                  alert("Coming Soon!");
                }
              }}
            >
              <img
                {...srcset(style.url, 60, 60, rows, cols)}
                alt={style.name}
                loading="lazy"
                style={{
                  borderRadius: 30,
                  // boxSizing: "border-box",
                }}
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.8) 0%, " +
                    "rgba(0,0,0,0.15) 70%, rgba(0,0,0,0) 100%)",
                  borderBottomRightRadius: 30,
                  borderBottomLeftRadius: 30,
                  fontWeight: 500,
                }}
                title={style.name}
                position="bottom"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
};
