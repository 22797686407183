import React from "react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { PaperPropsVariantOverrides } from "@mui/material";

// Default styles that can be overridden by your app
require("@solana/wallet-adapter-react-ui/styles.css");

const WHITELIST = [
  "5iz1LrHGdnasdxtXMv41TzMoS35kfrBWf6fwwAUjNftZ",
  "BooNMDhWJv5vpDd9QXvgFYp8eRauc38tPJ4cJnbr9itD",
  "Gqy1j8emuQQzqqGgodujCQSCdHuF6T3fGw5fBECTh9PN",
];

const ConnectWallet = ({
  onValidWallet,
  onInvalidWallet,
}: {
  onValidWallet: () => void;
  onInvalidWallet: () => void;
}) => {
  const wallet = useWallet();
  const pk = wallet.publicKey?.toBase58();

  React.useEffect(() => {
    if (pk && WHITELIST.includes(pk)) {
      onValidWallet();
    } else if (pk) {
      onInvalidWallet();
    }
  }, [pk]);

  // bypass wallet check by wring `window.password = "flopped"` in the console
  React.useEffect(() => {
    const interval = setInterval(() => {
      const g = window as any;
      if (g.password && g.password == "flopped") {
        onValidWallet();
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [onValidWallet]);

  return (
    <WalletModalProvider>
      <WalletMultiButton />
      {/* <WalletDisconnectButton /> */}
    </WalletModalProvider>
  );
};

export default ConnectWallet;
