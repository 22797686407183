import React from "react";
import { AudioManager } from "./lib/AudioManager";
import p5Types from "p5"; //Import this for typechecking and intellisense
import P5Container from "./P5Container";
import Box from "@mui/material/Box";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import ThemePicker from "./ThemePicker";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/core/ClickAwayListener";
import "@fontsource/roboto/300.css";
import { SoundprintState, SoundprintTheme } from "./soundprint/State";
import { DarkTheme } from "./soundprint/DiskTheme";

const Studio = ({
  onSetup,
  audioManager,
}: {
  onSetup: (p5: p5Types) => void;
  audioManager?: AudioManager;
}) => {
  const [isPaused, setIsPaused] = React.useState(false);
  const [isMuted, setisMuted] = React.useState(false);
  const [themePickerOpen, setThemePickerOpen] = React.useState<boolean>(false);
  const [ssState, setssState] = React.useState<SoundprintState>(
    new SoundprintState(new DarkTheme())
  );
  // const [currTheme, setCurrTheme] = React.useState<SoundprintTheme>(new DarkTheme());
  const onThemePicked = React.useCallback(
    (theme: SoundprintTheme) => {
      ssState.setTheme(theme);
      // setCurrTheme(theme);
    },
    [ssState]
  );

  console.log(themePickerOpen);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
      <Box
        style={{
          position: "absolute",
          height: "100vh",
          left: 0,
          top: 0,
          margin: 0,
          display: "flex",
        }}
      >
        <Box
          style={{
            marginTop: 61,
            display: "flex",
          }}
        >
          <Box
            style={{
              // marginTop: 61,
              display: "flex",
              flex: 1,
              padding: "1em",
              // height: "auto",
              // height: "100%",
              // alignSelf: "stretch",
              background: themePickerOpen
                ? "linear-gradient(to right, #888, #000)"
                : undefined,
              margin: 0,
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                setThemePickerOpen(true);
              }}
              sx={{
                position: "absolute",
                width: 150,
                display: !themePickerOpen ? undefined : "none",
              }}
            >
              {"Customize >>"}
            </Button>
            <Box
              sx={{
                display: themePickerOpen ? "flex" : "none",
                marginTop: "4em",
              }}
            >
              <ClickAwayListener
                onClickAway={
                  themePickerOpen ? () => setThemePickerOpen(false) : () => {}
                }
                mouseEvent={"onMouseDown"}
              >
                <ThemePicker onThemePicked={onThemePicked} />
              </ClickAwayListener>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            // marginLeft: "20em",
            // height: "100vh",
            // alignItems: "center",
          }}
        >
          <P5Container
            onSetup={onSetup}
            audioManager={audioManager}
            ssState={ssState}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100px",
              margin: 1,
            }}
          >
            {/* {isPaused ? (
              <PlayCircleIcon
                fontSize="large"
                onClick={() => setIsPaused(false)}
              />
            ) : (
              <PauseCircleIcon
                fontSize="large"
                onClick={() => setIsPaused(true)}
              />
            )}
            {isMuted ? (
              <VolumeUpIcon
                fontSize="large"
                onClick={() => setisMuted(false)}
              />
            ) : (
              <VolumeMuteIcon
                fontSize="large"
                onClick={() => setisMuted(true)}
              />
            )} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Studio;
