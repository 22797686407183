import React from "react";
import Sketch from "react-p5";
import p5Types from "p5"; //Import this for typechecking and intellisense
import "p5/lib/addons/p5.sound";

import "p5/lib/addons/p5.sound";

import SpaceBackground from "./static/art/background/space.png";
import IAlligatorDark from "./static/art/icon/Ialligatordark.png";
import { AudioManager } from "./lib/AudioManager";
import { SoundprintState } from "./soundprint/State";

// ssState: object describing how to draw the soundprint, given the current
// spectrum and position in the audio track.
const Studio = ({
  onSetup,
  ssState,
  audioManager,
}: {
  onSetup: (p5: p5Types) => void;
  ssState: SoundprintState;
  audioManager?: AudioManager;
}) => {
  let x = 50;
  const y = 50;

  const [mic, setMic] = React.useState<p5Types.AudioIn>();
  // const [p5, setP5] = React.useState<p5Types>();

  const [sound, setSound] = React.useState<p5Types.SoundFile>();
  const [fft, setFft] = React.useState<p5Types.FFT>();
  const [background, setBackground] = React.useState();
  const [onStartMicRecord, setOnStartMicRecord] = React.useState();

  // const [ssState, setssState] = React.useState<SoundprintState>(
  //   new SoundprintState()
  // );

  const [spectogramGraphics, setSpectrogramGraphics] =
    React.useState<p5Types.Graphics>();

  // React.useEffect(() => {
  //   if (fft && source) {
  //     fft.setInput(source);
  //   }
  // }, [fft, source]);

  // Once fft and audioManager are both constructed, wait for audioManager to be
  // initialised and then set the fft input.
  React.useEffect(() => {
    if (fft && audioManager) {
      const interval = setInterval(() => {
        if (audioManager.isInitialised()) {
          audioManager.setFFTInput(fft);
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [fft, audioManager]);

  // React.useEffect(() => {
  //   if (fft && audioManager && audioManager.isInitialised()) {
  //     audioManager.setFFTInput(fft);
  //   }
  // }, [fft, audioManager])

  // React.useEffect(() => {
  //   if (fft && audioEltRef.current) {
  //     fft.setInput(audioEltRef.current!);
  //   }
  // }, [fft, audioEltRef]);

  // console.log("Processing: source:", source);

  // fetch("http://localhost:4000/space.png").then((res) => {
  //   return res.blob();
  // }).then((blob) => {
  //   setBackground(URL.createObjectURL(blob));
  // });

  console.log("Rendering processing");

  //See annotations in JS for more information
  const setup = React.useCallback(
    (p5: p5Types, canvasParentRef: Element) => {
      console.log("setup");
      p5.createCanvas(600, 800).parent(canvasParentRef);

      (p5 as any).userStartAudio();

      // const mic = new (global as any).p5.AudioIn();
      // mic.start();
      const newfft = new (global as any).p5.FFT();
      // fft.setInput(mic);

      // if(source) {
      //   console.log("fft source set");
      //   newfft.setInput(source);
      // }

      // setMic(mic);
      setFft(newfft);

      // setFft(new (global as any).p5.FFT(0.8, 256));

      p5.background(220);

      // setp5Controller(new P5Controller(p5));

      // setOnStartMicRecord(() => {
      //   let mic: p5Types.AudioIn = new (p5 as any).AudioIn();
      //   mic.start();

      //   let recoder: p5Types.SoundRecorder = new (p5 as any).SoundRecorder(mic);

      //   let soundFile: p5Types.SoundFile = new (p5 as any).SoundFile();

      //   recoder.record(soundFile, 5);
      // })

      p5.loadImage(
        // "http://localhost:8000/static/art/background/space.png",
        SpaceBackground,
        (img) => {
          p5.image(img, 0, 0, p5.width, p5.height);
        }
      );

      p5.loadImage(
        // "http://localhost:8000/static/art/icon/Ialligatordark.png",
        IAlligatorDark,
        (img) => {
          img.resize(80, 80);
          p5.image(img, p5.width / 2 - 40, 640);
        }
      );

      setSpectrogramGraphics(p5.createGraphics(p5.width, p5.height));

      ssState.setup(p5);

      onSetup(p5);


      // p5.background(img);

      // const handleFile = (file: any) => {
      //   p5.print(file);
      //   if (file.type === "audio") {
      //     (p5 as any).loadSound(
      //       file.data,
      //       (sound: any) => {
      //         console.log(sound);
      //         setSound(sound);
      //       },
      //       (err: any) => console.log("error", err),
      //       (perc: number) => console.log(perc)
      //     );
      //   }
      // };

      // let input = p5.createFileInput(handleFile);
      // input.position(0, 0);
    },
    [onSetup]
  );

  const draw = React.useCallback(
    (p5: p5Types) => {
      // console.log("draw")
      // console.log("Draw")
      // p5.background(0);
      // p5.ellipse(x, y, 70, 70);
      // x++;

      // p5.background(220);

      // mic && console.log(mic.getLevel());

      // if (p5Controller === undefined || !p5Controller.p5ShouldDraw()) {
      //   return;
      // }

      // debugger;
      // p5.noStroke();
      // p5.fill(255, 0, 255);
      // for (let i = 0; i < spectrum.length; i++) {
      //   let x = p5.map(i, 0, spectrum.length, 0, p5.width);
      //   let h = -p5.height + p5.map(spectrum[i], 0, 255, p5.height, 0);
      //   p5.rect(x, p5.height, p5.width / spectrum.length, h);
      // }

      // let waveform = fft!.waveform();
      // p5.noFill();
      // p5.beginShape();
      // p5.stroke(20);
      // for (let i = 0; i < waveform.length; i++) {
      //   let x = p5.map(i, 0, waveform.length, 0, p5.width);
      //   let y = p5.map(waveform[i], -1, 1, 0, p5.height);
      //   p5.vertex(x, y);
      // }
      // p5.endShape();

      if (audioManager && audioManager.isInitialised()) {
        ssState.draw(p5, audioManager, fft!);
      }

      if (1 === 1) {
        return;
      }

      if (audioManager && audioManager.isInitialised()) {
        // let frac = 0;
        // if (audioEltRef.current!.duration) {
        //   frac = audioEltRef.current!.currentTime / audioEltRef.current!.duration;
        // } else {
        //   frac = micDuration;
        // }
        let frac = audioManager.getAudioProgress();
        // }

        // if (audioElt && source) {
        // const sound = p5Controller.soundFile!;
        // const frac = sound.currentTime() / sound.duration();

        // const frac = p5Controller.getSoundProgress();
        // console.log("frac", frac);
        const angleRad = p5.radians(90 + 361.0 * frac);

        // let spectrum = p5Controller.fft!.analyze(1024);
        let spectrum = fft!.analyze();

        // console.log(spectrum.reduce((a, b) => a + b));

        // console.log(frac)

        const g = p5!;

        for (let i = 0; i < 256; i++) {
          g.noFill();
          g.strokeWeight(2);
          // const colour = 255-p5.max(p5.min(100*p5.log( (1+ 20*spectrum[i])),255),0);
          const colour = 255 - p5.max(spectrum[i] * 2, 0);
          const specFrac = spectrum[i] / 255;

          g.push();
          g.colorMode(p5.HSL);
          const c = p5.color(
            ((angleRad - p5.PI / 2) * 360) / 6,
            100,
            100 * specFrac
          );
          g.pop();

          // debugger;

          g.stroke(p5.red(c), p5.green(c), p5.blue(c));

          const xdist = p5.min((256 - i) * p5.sin(angleRad), 256); //point's x deviation from the circle centre
          const ydist = p5.min((256 - i) * p5.cos(angleRad), 256);
          g.blendMode(p5.BLEND);
          g.point(p5.width / 2 + xdist, p5.width / 2 + ydist);

          // p5.image(g, 0, 0, p5.width, p5.height);
        }

        // for (let i = 0; i < 256; i += 40) {
        //   const BAR_POS_X = 75;
        //   const BAR_POS_Y = 680;
        //   const BAR_WIDTH = 3;
        //   const BAR_SPACING = 2;
        //   p5.rect(
        //     p5.width / 2 + BAR_POS_X + i * BAR_WIDTH + i * BAR_SPACING,
        //     BAR_POS_Y,
        //     BAR_WIDTH,
        //     -spectrum[i] * 2
        //   );
        // }
      }

      // text('tap to play', 20, 20);

      // if (sound) {
      //   // console.log(
      //   //   sound.duration(),
      //   //   sound.currentTime(),
      //   //   sound.isLoaded(),
      //   //   sound.isPlaying()
      //   // );
      //   if (!sound.isPlaying()) {
      //     console.log("sound.play()");
      //     sound.play(0);
      //   }
      // }
    },
    [audioManager]
  );
  return <Sketch setup={setup} draw={draw} />;
};

export default Studio;
