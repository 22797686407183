import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

const Stamp = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Button
        sx={{
          padding: "1em",
          borderRadius: 3,
          border: "none",
          background:
            "linear-gradient(220deg, rgb(112, 236, 157) 11.64%, rgb(112, 126, 255) 70.29%, rgb(129, 60, 255) 114.24%)",
          color: "white",
          fontWeight: "bold",
          width: 300,
          marginTop: "2em",
          marginBottom: "2em",
          "&:hover": {
            cursor: "pointer",
          },
          fontSize: "1em",
        }}
        onClick={() => alert("Coming soon :)")}
      >
        Mint
      </Button>
      <Button
        sx={{
          padding: "1em",
          borderRadius: 3,
          border: "none",
          background:
            "linear-gradient(220deg, rgb(112, 236, 157) 11.64%, rgb(112, 126, 255) 70.29%, rgb(129, 60, 255) 114.24%)",
          color: "white",
          fontWeight: "bold",
          width: 300,
          marginTop: "2em",
          marginBottom: "2em",
          "&:hover": {
            cursor: "pointer",
          },
          fontSize: "1em",
        }}
        onClick={() => alert("Coming soon :)")}
      >
        Merch Store
      </Button>
    </Box>
  );
};

export default Stamp;
