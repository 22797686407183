import { CurrPage } from "./Pages";
import Button from "@mui/material/Button";

const Navbar = ({
  currPage,
  onBackToAudio,
  onForwardToFinalise,
  onBackToStudio,
}: {
  currPage: CurrPage;
  onBackToAudio: () => void;
  onForwardToFinalise: () => void;
  onBackToStudio: () => void;
}) => {
  const content = {
    [CurrPage.Landing]: null,
    [CurrPage.AudioPicker]: (
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Button variant="outlined" disabled>{"Customise Soundprint >"}</Button>
      </div>
    ),
    [CurrPage.Studio]: (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button variant="outlined" onClick={onBackToAudio}>
          {"< Choose Audio"}
        </Button>
        <Button variant="outlined" onClick={onForwardToFinalise}>
          {"Finalize >"}
        </Button>
      </div>
    ),
    [CurrPage.Finalise]: (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <Button variant="outlined" onClick={onBackToStudio}>{"< Back to Soundprint"}</Button>
      </div>
    ),
  }[currPage];

  return (
    <div
      style={{
        width: "auto",
        minHeight: 60,
        borderBottom: "1px solid grey",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "2em",
        paddingRight: "2em",
      }}
    >
      {content}
    </div>
  );
};

export default Navbar;
