import React from "react";
import p5Types from "p5"; //Import this for typechecking and intellisense

enum CurrAudioSource {
  File,
  LiveMic,
  RecordMic,
}

export enum MicMode {
  Record,
  Viz,
}

export class AudioManager {
  /*
    When the app starts, audioManger is undefined, since it can't be created until `p5`
    exists.

    Once the constructor args are ready, audioManager can be constructed but will be in
    the `uninitialised` state.

    AudioManager is initialised by providing an audio input. At this point, processing
    can start drawing the soundprint.
    */
  audioEltRef: React.RefObject<HTMLAudioElement>;
  p5: p5Types;
  source?: AudioNode;
  micDurationSec?: number;
  audioEltDurationSec?: number;
  micStartTime?: number;
  currAudioSource?: CurrAudioSource;

  constructor(audioEltRef: React.RefObject<HTMLAudioElement>, p5: p5Types) {
    console.log("AudioManager constructor");
    this.audioEltRef = audioEltRef;
    this.p5 = p5;
  }

  initFromFile(file: File, durationSec?: number) {
    const fileURL = URL.createObjectURL(file);
    this.audioEltRef.current!.src = fileURL;
    const context = (this.p5 as any).getAudioContext() as AudioContext;
    this.source = context.createMediaElementSource(this.audioEltRef.current!);
    this.source.connect(context.destination);
    this.audioEltRef.current!.play();
    this.currAudioSource = CurrAudioSource.File;
    if (durationSec) {
        this.audioEltDurationSec = durationSec;
    }
  }

  initFromMic(
    mode: MicMode,
    durationSec: number,
    setIsStarting?: (isStarting: boolean) => void
  ) {
    setIsStarting && setIsStarting(true);
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream: MediaStream) => {
        console.log("onMicStart");
        setIsStarting && setIsStarting(false);
        this.micDurationSec = durationSec;

        const context = (this.p5 as any).getAudioContext() as AudioContext;
        this.source = context.createMediaStreamSource(stream);
        this.currAudioSource = CurrAudioSource.LiveMic;

        if (mode == MicMode.Viz) {
          this.micStartTime = new Date().getTime();
        } else if (mode == MicMode.Record) {
          //   this.audioEltRef.current!.srcObject = stream;
          //   this.audioEltRef.current!.muted = true;
          //   this.audioEltRef.current!.play();

          const dest = context.createMediaStreamDestination();
          this.source.connect(dest);
          const recorder = new MediaRecorder(dest.stream);

          //   const recorder = new MediaRecorder(
          //     (this.audioEltRef.current! as any).captureStream()
          //   );

          recorder.onstart = (e) => {
            console.log("mic recorder onstart. time", new Date().getTime());
            this.micStartTime = new Date().getTime();
          };

          let data: any = [];
          recorder.ondataavailable = (e) => {
            console.log("got data", e);
            data.push(e.data);
          };
          recorder.onstop = (e) => {
            const recordedBlob = new Blob(data, { type: "audio/webm" });
            console.log("Recorded blob: ", recordedBlob);
            this.initFromMicRecording(recordedBlob, durationSec);
          };

          recorder.start();

          setTimeout(() => recorder.stop(), durationSec * 1000);
        }
      });
  }

  initFromMicRecording(blob: Blob, duration: number) {
    this.initFromFile(blob as File, duration);
  }

  isInitialised(): boolean {
    return !!this.source;
  }

  setFFTInput(fft: p5Types.FFT) {
    console.log("set fft input", fft);
    fft.setInput(this.source);
  }

  // Return a number in [0, 1] representing the current progress through the audio track.
  getAudioProgress(): number {
    if (this.currAudioSource! == CurrAudioSource.File) {
      return (
        this.audioEltRef.current!.currentTime /
        (this.audioEltDurationSec! || this.audioEltRef.current!.duration)
      );
    } else if (this.currAudioSource! == CurrAudioSource.LiveMic) {
      return (
        ((new Date().getTime() - this.micStartTime!) /
          (this.micDurationSec! * 1000)) %
        1
      );
    } else {
      return -1;
    }
  }

  destroy() {
    this.audioEltRef.current!.pause();
    this.audioEltRef.current!.currentTime = 0;
    this.source?.disconnect();
  }
}
