import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Slider from "@mui/material/Slider";
import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import MUIButton from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import p5Types from "p5"; //Import this for typechecking and intellisense
import AudioTrack from "@material-ui/icons/Audiotrack";
import P5Controller from "./lib/P5Controller";
import LinearProgress from "@mui/material/LinearProgress";
import { AnyCnameRecord } from "dns";
import { AudioManager, MicMode } from "./lib/AudioManager";
import Input from "@mui/material/Input";

const useStyles = makeStyles({
  smDropzone: {
    minHeight: 40,
    padding: 5,
    backgroundColor: "#555",
    borderRadius: 10,
  },
});

const SoundPicker = ({
  audioManager,
  onNextPage,
}: {
  audioManager?: AudioManager;
  onNextPage?: () => void;
}) => {
  const [soundStarting, setSoundStarting] = React.useState(false);
  const [micStarting, setMicStarting] = React.useState(false);
  const [micDuration, setMicDuration] = React.useState<number>(20);
  const [micMode, setMicMode] = React.useState<MicMode>(MicMode.Viz);

  React.useEffect(() => {
    if (!onNextPage) return;
    const interval = setInterval(() => {
      if (
        audioManager &&
        audioManager.isInitialised() &&
        audioManager.getAudioProgress() > 0
      ) {
        setSoundStarting(false);
        onNextPage();
        clearInterval(interval);
      }
    });
    return () => clearInterval(interval);
  }, [audioManager, onNextPage, setSoundStarting]);

  const onFileUpload = React.useCallback(
    (file: File) => {
      setSoundStarting(true);
      audioManager!.initFromFile(file);
    },

    [audioManager, setSoundStarting]
  );

  // const onFileUpload = React.useCallback(
  //   (file: File) => {
  //     const fileURL = URL.createObjectURL(file);
  //     // let audioElement = document.getElementById(
  //     //   "audio_player"
  //     // )! as HTMLAudioElement;
  //     audioEltRef.current!.src = fileURL;
  //     const context = (p5 as any).getAudioContext();
  //     // const context = new AudioContext();
  //     const source = context.createMediaElementSource(audioEltRef.current!);
  //     source.connect(context.destination);
  //     audioEltRef.current!.play();

  //     setSource(source);
  //     // setAudioElt(audioElement);

  //     // const interval = setInterval(() => {
  //     //   const loaded = audioElement.buffered.end(0) / audioElement.duration;
  //     //   console.log("audio loaded: ", loaded);
  //     // }, 200)

  //     //   p5Controller.loadSoundFile(file);
  //     //   setSoundStarting(true);
  //     //   p5Controller.registerOnSoundStart(() => {
  //     //     onSoundStarted();
  //     //     setSoundStarting(false);
  //     //   });
  //     // },
  //     // [p5Controller, setSoundStarting]
  //   },
  //   [p5]
  // );

  const onMicStart = React.useCallback(
    () => audioManager!.initFromMic(micMode, micDuration, setMicStarting),
    [audioManager, micDuration, setMicStarting, micMode]
  );

  // const onMicStart = React.useCallback(() => {
  //   setMicStarting(true);
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true, video: false })
  //     .then((stream: MediaStream) => {
  //       console.log("onMicStart");
  //       setMicStarting(false);

  //       // let audioElement = document.getElementById(
  //       //   "audio_player"
  //       // )! as HTMLAudioElement;
  //       audioEltRef.current!.srcObject = stream;
  //       audioEltRef.current!.play();
  //       // audioElement.srcObject = stream;

  //       return new Promise(
  //         (resolve) => (audioEltRef.current!.onplaying = resolve)
  //       );
  //     })
  //     .then(() =>
  //       recordStream(
  //         (audioEltRef.current! as any).captureStream(),
  //         micDuration! * 1000
  //       )
  //     )
  //     .then((recordedChunks: any) => {
  //       let recordedBlob = new Blob(recordedChunks, { type: "audio/webm" });

  //       audioEltRef.current!.src = URL.createObjectURL(recordedBlob);
  //     });

  // })
  // audioElement.src = URL.createObjectURL(stream);
  // audioElement.play;

  // const context = (p5 as any).getAudioContext() as AudioContext;
  // const source = context.createMediaStreamSource(stream);
  // setSource(source);
  // console.log("setSource to mic source");

  // const recordedChunks = [] as any;
  // const mediaRecorder = new MediaRecorder(stream, {
  //   mimeType: "audio/webm",
  // });
  // mediaRecorder.addEventListener("dataavailable", (e) => {
  //   e.data.size > 0 && recordedChunks.push(e.data);
  // });
  // mediaRecorder.addEventListener("stop", () => {
  //   const audioURL = URL.createObjectURL(new Blob(recordedChunks));
  //   console.log(audioURL);
  // });

  // setTimeout(() => mediaRecorder.stop(), micDuration);

  // mediaRecorder.start();
  // console.log("Finished onMicStart");
  // });
  // }, [audioEltRef]);

  // const onStartMicRecord = React.useCallback(
  //   (duration: number) => {
  //     p5Controller.startMicRecord(duration);
  //     setMicStarting(true);
  //     console.log("begin mic starting");

  //     p5Controller.registerOnMicStart(() => {
  //       console.log("mic starting complete");
  //       setMicStarting(false);
  //     });

  //     // p5Controller.startMicRecording();
  //     // setSoundStarting(true);
  //     // p5Controller.registerOnSoundStart(() => {
  //     //   onSoundStarted();
  //     //   setSoundStarting(false);
  //     // });
  //   },
  //   [p5Controller, setMicStarting]
  // );

  // console.log("mic starting:", micStarting);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={4}
          // className="px-4"
          style={{
            borderRight: "1px solid grey",
            padding: 20,
          }}
        >
          <SoundFilePicker onFileUpload={onFileUpload} />
          <Box sx={{ marginTop: 2 }}>
            {soundStarting && (
              <>
                <Box mt={2} />
                <Box>
                  <LinearProgress />
                  <FormHelperText>Importing sound...</FormHelperText>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ borderRight: "1px solid grey" }}>
          <MicrophonePicker
            onStartMicRecord={onMicStart}
            micDuration={micDuration}
            setMicDuration={setMicDuration}
            micMode={micMode}
            setMicMode={setMicMode}
          />
          {/* <MicrophonePicker onStartMicRecord={() => {}} /> */}

          <Box sx={{ marginTop: 0 }}>
            {micStarting && (
              <Box sx={{ mx: 3 }}>
                <LinearProgress />
                <FormHelperText>Initialising mic...</FormHelperText>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={4} style={{ padding: 20 }}>
          <TTSPicker />
        </Grid>
      </Grid>
    </Box>
  );
};

const SoundFilePicker = ({
  onFileUpload,
}: {
  onFileUpload: (file: File) => void;
}) => {
  const classes = useStyles();

  return (
    <Box>
      <div style={{ marginBottom: "1em", fontWeight: "bold" }}>
        Upload a sound file
      </div>
      <Input
        type="file"
        inputProps={{ accept: "audio/*" }}
        onChange={(e) => onFileUpload((e.target as any).files[0])}
      ></Input>
      {/* <DropzoneArea
        acceptedFiles={["audio/*"]}
        filesLimit={1}
        showFileNames={true}
        maxFileSize={1e9}
        dropzoneText="Drag and drop a sound file here or click to upload"
        dropzoneClass="dropzone"
        dropzoneParagraphClass="dropzone-paragraph"
        // dropzoneParagraph="Drop a sound file here or click to upload"
        onChange={(files) => {
          console.log(files);
        }}
        classes={{
          root: classes.smDropzone,
        }}
        getPreviewIcon={() => <AudioTrack />}
        onDrop={(files) => {
          console.log(files);
          onFileUpload(files[0]);
        }}
        // style = {{height: 200}}
      /> */}
    </Box>
  );
};

const MicrophonePicker = ({
  onStartMicRecord,
  micDuration,
  setMicDuration,
  micMode,
  setMicMode,
}: {
  onStartMicRecord: () => void;
  micDuration?: number;
  setMicDuration: (duration: number) => void;
  micMode: MicMode;
  setMicMode: (mode: MicMode) => void;
}) => {
  return (
    <Box
      sx={{
        // width: "100%",
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        padding: "20px",
      }}
    >
      <Box sx={{ fontWeight: "bold" }}>Use your microphone</Box>
      <Box mt={1} />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MUIButton
          className="mb-3 my-2"
          variant="outlined"
          style={{ width: "50%", marginBottom: 10 }}
          onClick={() => onStartMicRecord()}
        >
          Start Listening 🎙️
        </MUIButton>
        {/* <FormLabel component="legend">Mode</FormLabel>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="Mode"
            name="row-radio-buttons-group"
            value={micMode}
            onChange={(e) => {
              setMicMode(parseInt(e.target.value));
            }}
          >
            <FormControlLabel
              value={MicMode.Viz}
              control={<Radio />}
              label="Visualise"
            />
            <FormControlLabel
              value={MicMode.Record}
              control={<Radio />}
              label="Record & Playback"
            />
          </RadioGroup>
        </FormControl> */}
        <Box sx={{ width: "100%", paddingLeft: 10, paddingRight: 10 }}>
          <Box sx={{ display: "flex" }}>
            <Typography gutterBottom>Soundprint duration</Typography>
          </Box>
          <Slider
            // defaultValue={10}
            value={micDuration}
            onChange={(e, v) => setMicDuration(v as number)}
            step={1}
            min={1}
            max={60}
            valueLabelDisplay="auto"
            // sx={{ marginBottom: 10 }}
            marks={[1, 5, 10, 15, 20, 30, 45, 60].map((val) => ({
              value: val,
              label: val,
            }))}
          />
        </Box>
      </Box>
    </Box>
  );
};

const TTSPicker = () => {
  return (
    <Box>
      <div style={{ fontWeight: "bold" }}>
        🚧 Use text to speech (coming soon) 🚧
      </div>
      <Box
        component="form"
        sx={{
          // "& .MuiTextField-root": { m: 1, width: "25ch" },
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "space-around",
          marginTop: 2,
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-multiline-static"
          label="Text to say"
          multiline
          rows={2}
          // defaultValue="Default Value"
          className="my-4"
          sx={{
            width: "100%",
          }}
          disabled
        />
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={8}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={0}
              label="Age"
              style={{ width: "100%" }}
              disabled
              // onChange={handleChange}
            >
              <MenuItem value={0}>Amy (English UK)</MenuItem>
              <MenuItem value={1}>Samuel</MenuItem>
              <MenuItem value={2}>Toby</MenuItem>
            </Select>
            <FormHelperText>Pick voice</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <MUIButton
              variant="outlined"
              color="primary"
              sx={{ width: "100%", height: 55 }}
              disabled
            >
              Generate
            </MUIButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SoundPicker;
