import React from "react";
import Studio from "./Studio";
import SingleAccordion from "./lib/SingleAccordion";
import SoundPicker from "./SoundPicker";
import Grid from "@mui/material/Grid";
import P5Controller from "./lib/P5Controller";
import p5Types from "p5"; //Import this for typechecking and intellisense
import { AudioManager } from "./lib/AudioManager";
import Stamp from "./Stamp";
import Landing from "./Landing";
import { useWallet } from "@solana/wallet-adapter-react";
import { createTheme, ThemeProvider } from "@mui/material";
import Navbar from "./Navbar";

export enum CurrPage {
  Landing,
  AudioPicker,
  Studio,
  Finalise,
}

const Pages = () => {
  document.getElementById("p5_loading")?.remove();

  const [currPage, setCurrPage] = React.useState<CurrPage>(CurrPage.Landing);

  const { publicKey } = useWallet();

  console.log(publicKey?.toBase58());

  const [p5, setP5] = React.useState<p5Types>();

  const audioEltRef = React.useRef<HTMLAudioElement>(null);

  const [audioManager, setAudioManger] = React.useState<AudioManager>();

  // Don't init audioManager until the required objects are created.
  React.useEffect(() => {
    if (audioEltRef.current && p5) {
      setAudioManger(new AudioManager(audioEltRef, p5));
    }
  }, [audioEltRef, p5, setAudioManger]);

  const onSetup = React.useCallback(
    (p5: p5Types) => {
      setP5(p5);
    },
    [setP5]
  );

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  console.log("p5", p5);

  if (currPage == CurrPage.Landing) {
    return <Landing onNextPage={() => setCurrPage(CurrPage.AudioPicker)} />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          margin: 0,
          position: "absolute",
          width: "100vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Navbar
          currPage={currPage}
          onBackToAudio={() => {
            setCurrPage(CurrPage.AudioPicker);
            audioManager?.destroy();
            setAudioManger(new AudioManager(audioEltRef, p5!));
          }}
          onForwardToFinalise={() => {
            setCurrPage(CurrPage.Finalise);
          }}
          onBackToStudio={() => {
            setCurrPage(CurrPage.Studio);
          }}
        />
        <div
          style={{
            // margin: 30,
            borderRadius: 20,
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <div
            style={{
              display: currPage == CurrPage.AudioPicker ? "flex" : "none",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <SoundPicker
              audioManager={audioManager}
              onNextPage={
                currPage == CurrPage.AudioPicker
                  ? () => setCurrPage(CurrPage.Studio)
                  : undefined
              }
            />
          </div>
          <div
            style={{
              display: currPage == CurrPage.Studio ? "flex" : "none",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Studio onSetup={onSetup} audioManager={audioManager} />
          </div>
          <div
            style={{
              display: currPage == CurrPage.Finalise ? "flex" : "none",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Stamp />
          </div>
        </div>
        <audio
          id="audio_player"
          ref={audioEltRef}
          hidden
          loop
          onCanPlay={() => console.log("audio: canplay")}
          onPlaying={() => console.log("audio: playing")}
          onPlay={() => console.log("audio: play")}
          onEnded={() => console.log("audio: ended")}
        />
      </div>
    </ThemeProvider>
  );
};

export default Pages;
