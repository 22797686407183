import BackgroundImg from "./static/landing-background.png";
import ConnectWallet from "./lib/ConnectWallet";

const Landing = ({ onNextPage }: { onNextPage: () => void }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${BackgroundImg})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        <div
          style={{
            width: 600,
          }}
        >
          <div
            style={{
              // fontFamily: "Roboto",
              fontSize: 64,
              fontWeight: 300,
              color: "white",
            }}
          >
            Soundprint Studio
          </div>
          <div
            style={{
              marginTop: "3em",
            }}
          >
            An exciting new audio visualiser for the NFT era - available
            exclusively to holders of an{" "}
            <a style={{ color: "white" }} href="https://endangeredsounds.art">
              <i>Endangered Soundprint</i>
            </a>
          </div>
          <div
            style={{
              marginTop: "3em",
            }}
          >
            Connect a wallet containing an <i>Endangered Soundprint</i> to
            enter.
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            <ConnectWallet
              onValidWallet={onNextPage}
              onInvalidWallet={() =>
                alert(
                  "Sorry, only select beta testers may access Soundprint Studio at this time. Check back soon!"
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
