import { FFT } from "p5";
import p5Types from "p5";
import { AudioManager } from "../lib/AudioManager";
import { SoundprintTheme } from "./State";

interface SetColorArgs {
  p5: p5Types;
  posXFrac: number; // Horizontal position of the current bar, as a fraction from 0 to 1.
  posYFrac: number; // Vertical position of the current pixel, along the current bar, as a fraction from 0 to 1.
  specFrac: number; // Specular power of the current pixel, as a fraction from 0 to 1.
}

abstract class PolaroidTheme extends SoundprintTheme {
  setup(p5: p5Types) {
    p5.background("#fff");

    p5.push();
    p5.strokeWeight(1);
    p5.textStyle(p5.BOLD);
    p5.fill(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.textSize(50);
    p5.text("Polaroid", 0, p5.width - 25, p5.width, 50);

    p5.textStyle(p5.ITALIC);
    p5.textSize(14);
    p5.text("By Endangered Sounds", 0, p5.width + 25, p5.width, 20);
    p5.pop();

    this.init(p5);
  }

  draw(p5: p5Types, audioManager: AudioManager, fft: FFT): void {
    const frac = audioManager.getAudioProgress();
    const spectrum = fft.analyze();

    for (let i = 0; i < 512; i++) {
      p5.push();
      p5.noFill();
      p5.strokeWeight(1);

      const specFrac = spectrum[i] / 512;
      const heightFrac = (512 - i) / 512;

      this.setColor({
        p5: p5,
        posXFrac: frac,
        posYFrac: heightFrac,
        specFrac: specFrac,
      });

      const margin = 50;
      const startX = margin;
      const endX = p5.width - margin;
      const startY = margin;
      const endY = p5.width - 2 * margin;

      p5.blendMode(p5.BLEND);
      p5.point(p5.lerp(startX, endX, frac), p5.lerp(startY, endY, heightFrac));
      p5.pop();
    }
  }

  init(p5: p5Types): void {
    // Do nothing.
  }

  abstract setColor(args: SetColorArgs): void;
}

export class PolaroidDarkTheme extends PolaroidTheme {
  setColor({ p5, specFrac }: SetColorArgs): void {
    p5.stroke(255 * Math.pow(specFrac, 0.6));
  }
}

export class PolaroidIceTheme extends PolaroidTheme {
  setColor({ p5, specFrac }: SetColorArgs): void {
    p5.push()
    p5.colorMode(p5.HSL);
    const c = p5.color(230, 50, 100 * Math.pow(specFrac, 0.5));
    p5.pop();
    p5.stroke(p5.red(c), p5.green(c), p5.blue(c));
  }
}

export class PolaroidSpectrumTheme extends PolaroidTheme {
  setColor({ p5, posXFrac, specFrac }: SetColorArgs) {
    p5.push();
    p5.colorMode(p5.HSL);
    const c = p5.color(posXFrac * 360, 100, 100 * Math.pow(specFrac, 0.7));
    p5.pop();
    p5.stroke(p5.red(c), p5.green(c), p5.blue(c));
  }
}
