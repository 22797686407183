import { SoundprintTheme } from "./State";
import p5Types from "p5"; //Import this for typechecking and intellisense
import { AudioManager } from "../lib/AudioManager";
import ArcadeBackground from "../static/art/background/arcade.png";
import RecordBackground from "../static/art/background/vinyl.png";
import SpaceBackground from "../static/art/background/space.png";
import ZenStamp from "../static/art/background/zenStamp.png";
import { stroke_hue_interpolate } from "./colour_utils";

interface SetColorArgs {
  p5: p5Types;
  angleRad: number; // Angle of the current ray around the disk.
  radiusFrac: number; // Radius of the current pixel on the current ray, as a fraction from 0 to 1.
  specFrac: number; // Specular power of the current pixel, as a fraction from 0 to 1.
}

abstract class DiskTheme extends SoundprintTheme {
  draw(p5: p5Types, audioManager: AudioManager, fft: p5Types.FFT) {
    const frac = audioManager.getAudioProgress();
    const angleRad = p5.radians(90 + 361.0 * frac);
    const spectrum = fft.analyze();

    for (let i = 0; i < 256; i++) {
      p5.noFill();

      const specFrac = spectrum[i] / 255;
      const radiusFrac = (256 - i) / 256;
      p5.strokeWeight(p5.lerp(1, 4, radiusFrac));

      p5.push();
      p5.colorMode(p5.RGB, 255, 255, 255, 1);
      this.setColor({
        p5: p5,
        angleRad: angleRad,
        radiusFrac: (256 - i) / 256,
        specFrac: specFrac,
      });

      const xdist = p5.min((256 - i) * p5.sin(angleRad), 256); //point's x deviation from the circle centre
      const ydist = p5.min((256 - i) * p5.cos(angleRad), 256);
      p5.blendMode(p5.BLEND);
      p5.point(p5.width / 2 + xdist, p5.width / 2 + ydist);
      p5.pop();
    }
  }

  abstract setColor(args: SetColorArgs): void;
}

export class DarkTheme extends DiskTheme {
  setup(p5: p5Types) {
    p5.background("#000000");
  }

  setColor({ p5, specFrac }: SetColorArgs) {
    p5.stroke(255 * specFrac);
  }
}

export class LightTheme extends DiskTheme {
  setup(p5: p5Types) {
    p5.background("#fff");
  }

  setColor({ p5, specFrac }: SetColorArgs) {
    p5.stroke(255 - 255 * specFrac);
  }
}

export class SpectrumTheme extends DiskTheme {
  setup(p5: p5Types) {
    p5.background("#000000");
  }

  setColor({ p5, angleRad, specFrac }: SetColorArgs) {
    p5.push();
    p5.colorMode(p5.HSL);
    const c = p5.color(((angleRad - p5.PI / 2) * 360) / 6, 100, 100 * specFrac);
    p5.pop();
    p5.stroke(p5.red(c), p5.green(c), p5.blue(c));
  }
}

export class ArcadeTheme extends DiskTheme {
  greenHue = 145;
  purpleHue = 300;

  setup(p5: p5Types) {
    p5.loadImage(ArcadeBackground, (img) => {
      p5.image(img, 0, 0, p5.width, p5.height);
    });
  }

  setColor({ p5, angleRad, specFrac }: SetColorArgs) {
    stroke_hue_interpolate(
      p5,
      this.greenHue,
      this.purpleHue,
      angleRad,
      specFrac
    );
  }
}

export class SolanaTheme extends DiskTheme {
  greenHue = 145;
  purpleHue = 300;

  setup(p5: p5Types) {
    p5.background("#000");
  }

  setColor({ p5, angleRad, specFrac }: SetColorArgs) {
    stroke_hue_interpolate(
      p5,
      this.greenHue,
      this.purpleHue,
      angleRad,
      specFrac
    );
  }
}

export class CottonCandy extends DiskTheme {
  blueHue = 190;
  purpleHue = 300;

  setup(p5: p5Types) {
    p5.background("#fff");
  }

  setColor({ p5, angleRad, specFrac }: SetColorArgs): void {
    stroke_hue_interpolate(
      p5,
      this.blueHue,
      this.purpleHue,
      angleRad,
      specFrac,
      false
    );
  }
}

export class RecordTheme extends DiskTheme {
  setup(p5: p5Types) {
    p5.loadImage(RecordBackground, (img) => {
      p5.image(img, 0, 0, p5.width, p5.height);
    });
  }

  setColor({ p5, specFrac, radiusFrac }: SetColorArgs): void {
    p5.stroke(255 * specFrac, radiusFrac > 0.3141 ? 255 : 0);
  }
}

export class SpaceTheme extends DiskTheme {
  setup(p5: p5Types) {
    p5.loadImage(SpaceBackground, (img) => {
      p5.image(img, 0, 0, p5.width, p5.height);
    });
  }

  setColor({ p5, radiusFrac, specFrac }: SetColorArgs): void {
    const r = p5.map(radiusFrac, 0, 1, 188, 195);
    const g = p5.map(radiusFrac, 0, 1, 255, 139);
    const b = p5.map(radiusFrac, 0, 1, 255, 181);
    p5.stroke(r, g, b, specFrac);
  }
}

export class ZenTheme extends DiskTheme {
  setup(p5: p5Types) {
    p5.background("#fff");
    p5.loadImage(ZenStamp, (img) => {
      p5.image(img, 440, 540, 55, 55);
    });
  }

  setColor({ p5, specFrac }: SetColorArgs): void {
    p5.stroke(255 - 255 * specFrac);
  }
}
