import p5Types from "p5";

export const stroke_hue_interpolate = (
  p5: p5Types,
  fromHueDeg: number,
  toHueDeg: number,
  currPosRad: number,
  specFrac: number,
  controlLightness: boolean = true
) => {
  p5.push();
  p5.colorMode(p5.HSL);
  const minAngleRad = Math.abs(Math.min(currPosRad, p5.PI * 2 - currPosRad));
  const hue = p5.lerp(fromHueDeg, toHueDeg, minAngleRad / p5.PI);
  let c;
  if (controlLightness) {
    c = p5.color(hue, 100, 100 * specFrac);
  } else {
    c = p5.color(hue, 100, 100 - (50 * specFrac));
  }
  p5.pop();
  p5.stroke(p5.red(c), p5.green(c), p5.blue(c));
};
