import p5Types from "p5"; //Import this for typechecking and intellisense
import { AudioManager } from "../lib/AudioManager";

export abstract class SoundprintTheme {
  abstract setup(p5: p5Types): void;

  abstract draw(
    p5: p5Types,
    audioManager: AudioManager,
    fft: p5Types.FFT
  ): void;
}

export class SoundprintState {
  theme: SoundprintTheme;
  isSetup: boolean;

  constructor(theme: SoundprintTheme) {
    this.theme = theme;
    this.isSetup = false;
  }

  setTheme(theme: SoundprintTheme) {
    this.theme = theme;
    this.isSetup = false;
  }

  setup(p5: p5Types) {
    this.theme.setup(p5);
    this.isSetup = true;
  }

  draw(p5: p5Types, audioManager: AudioManager, fft: p5Types.FFT) {
    if (!this.isSetup) {
      this.setup(p5);
    }
    this.theme.draw(p5, audioManager, fft);
  }
}
